.input-label {
    font-size: 12px;
    color: #E1E1E1;
}

.input-field {
    background: #F5F6F6;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
}

.input-error {
    .input-label {
        color: #FF5737;
    }
    .invalid-feedback {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #FF5737;
    }
}

.input-error-container {
    display: flex;
    align-items: center;
    margin-top: 30px;
    height: 0px;
    > svg {
        color: #FF5737;
        width: 0.7em;
        height: 0.7em;
    }
    .MuiFormHelperText-root {
        font-size: 12px;
        color: #FF5737;
        margin-bottom: 2px;
    }
}

.label-form-select {
    border: 1px solid #BFBFC2;
    color: #0088ce;
}

.label-form-select:focus {
    border: 1px solid #4fc3ff !important;
}

.toggle-button {
    background-color: transparent !important;
    color: #333 !important;
    border: none !important;
    padding: 0 1rem 0 0 !important;
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    svg {
        font-size: 22px;
    }
}

.generic-input {
    .MuiPaper-root {
        padding: 2px 4px;
        display: flex;
        align-items: center;
        width: 400px;
    }
    .MuiInputBase-input {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }
}

.generic-select {
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    .MuiOutlinedInput-root {
        background-color: #FFF;
        width: 165px;
        height: 30px;
    }
    .MuiInputBase-input {
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
    }
}