.parametrage-container {
  .container-body {
    height: 15%;
    .MuiGrid-item {
      margin-top: 0;
    }
  }
  .container-footer {
    height: auto;
    .MuiGrid-item {
      margin-bottom: 0;
    }
  }
}

.myp-import-autocomplete {
    .MuiTextField-root {
        background: #FFFFFF;
        border: 1px solid rgba(24, 59, 104, 0.1);
        border-radius: 4px;
        width: 610px;
        color: #9D327A;
    }
    .MuiAutocomplete-input::placeholder {
        color: rgba(189, 45, 95, 0.3);
    }
}

.share-user-list-autocomplete {
  .MuiTextField-root {
      background: #FFFFFF;
      border: 1px solid rgba(24, 59, 104, 0.1);
      border-radius: 4px;
      width: 610px;
      color: #9D327A;
  }
  .MuiAutocomplete-input::placeholder {
      color: rgba(0, 0, 0, 0.5);
  }
}

.custom-format-switch {
    width: 28px !important;
    height: 16px !important;
    padding: 0 !important;
    display: flex !important;
    .MuiSwitch-switchBase {
      padding: 2px;
    }
    .Mui-checked {
        transform: translateX(11px) !important;
        color: #fff !important;
        +.MuiSwitch-track {
            background-color: #AC3D88 !important;
        }
    }
    .MuiSwitch-thumb {
      box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
      width: 12px;
      height: 12px;
      border-radius: 6px;
    }
    .MuiSwitch-track {
      border-radius: 8px;
      opacity: 1 !important;
      background-color: rgba(221, 124, 189, 0.3) !important;
      box-sizing: border-box;
    }
}

.attributs-mapping-error {
    height: 66px !important;
  .input-error-container {
    margin-top: 15px !important;
  }
}