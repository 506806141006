.myp-container {
  position: relative;
  width: 100%;
  background: #ffffff;
  box-shadow: none !important;
  border-radius: 0 !important;
  display: flex;
  margin-top: 44px;
}

.myp-container-sp {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #e2e7ef;
  box-shadow: none !important;
  border-radius: 0 !important;
  display: flex;
}

.myp-btn-container {
  width: 250px;
  height: 201px;
  position: fixed;
  background: white;
  padding: 20px 40px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  border-right: 1px solid #e2e7ef;
  border-bottom: 1px solid #e2e7ef;
}

.myp-import-portfolio-btn {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px !important;
  gap: 10px;
  width: 173px;
  height: 45px;
  background-color: #41a8f9 !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  white-space: unset !important;
}

.myp-settings-btn {
  padding: 12px !important;
  height: 44px;
  border: 1px solid #e6eaee !important;
  border-radius: 8px !important;
  text-transform: none !important;
  background-color: transparent !important;
  color: #414141 !important;
  box-shadow: none !important;
}

.myp-add-operation-btn {
  width: 200px;
  height: 45px;
  border: 1px solid #e6eaee !important;
  border-radius: 8px !important;
  text-transform: none !important;
  background-color: transparent !important;
  color: #414141 !important;
  box-shadow: none !important;
}

.myp-tools-container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0px;
  gap: 16px;
  position: fixed;
  z-index: 6;
  right: 20px;
  top: 120px;
  background: white;
  width: 50%;
  .MuiIconButton-root {
    padding: 10px !important;
    border: 1px solid #e6eaee !important;
    border-radius: 8px !important;
  }
  .MuiButton-root {
    padding: 10px !important;
    border: 1px solid #e6eaee !important;
    border-radius: 8px !important;
    text-transform: none !important;
    background-color: transparent !important;
    color: #414141 !important;
    box-shadow: none !important;
  }
}

.pr-creation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-height: 80%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4375rem;
  outline: 0;
  padding: 1.5rem;
  gap: 25px;
}

.pr-dropdown-header {
  display: flex;
  justify-content: space-between;
  height: 32px;
  margin-bottom: 24px;
  width: 100%;
  .MuiTypography-root {
    font-weight: 900;
    font-size: 12px;
    display: flex;
    align-self: center;
  }
  .MuiIconButton-root {
    color: #3c3c3b;
    .MuiSvgIcon-root {
      width: 0.8em;
      height: 0.8em;
    }
  }
}

.pr-modal-body {
  overflow: auto;
}

.pr-fields {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-bottom: 20px;
  gap: 24px;
  .generic-select {
    margin-right: 0;
  }
  .generic-input {
    .MuiPaper-root {
      background-color: #ffffff;
      border: 1px solid #bfbfc2;
      border-radius: 4px;
      width: 200px;
    }
    .input-error-container {
      margin-top: 20px;
      margin-bottom: 5px;
      height: 100%;
    }
  }
  .MuiIconButton-root {
    color: #3c3c3b;
  }
  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }
}

.remove-pr-btn-container {
  display: flex;
  align-items: center;
  margin-left: 25px;
  .MuiIconButton-root {
    width: 30px;
    height: 30px;
  }
}

.error-remove-btn {
  align-items: start;
  margin-top: 30px;
}

.add-pr-btns-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.add-pr-btn {
  padding: 6px 6px 6px 8px !important;
  width: 398px;
  height: 40px;
  background-color: #41a8f9 !important;
  border-radius: 30px !important;
  outline: none !important;
}

.cancel-add-pr-btn {
  padding: 6px 6px 6px 8px !important;
  width: 398px;
  height: 40px;
  border: 1px solid #535353 !important;
  border-radius: 30px !important;
  background-color: #fff !important;
  color: #333 !important;
  outline: none !important;
}

.importance-checkbox {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  .MuiFormControlLabel-root {
    margin-top: 8px;
  }
}

.error-importance-checkbox {
  align-items: start;
  margin-top: 15px;
}

.card-context {
  position: fixed;
  top: 270px;
  background: aliceblue;
  z-index: 15;
}

.line-charts-container {
  overflow-x: auto;
}

.line-charts-container::-webkit-scrollbar {
  display: none;
}

.line-chart-container-pr {
  background: white;
  padding-top: 50px;
  width: calc(100% - 250px);
  left: 250px;
  overflow-x: scroll;
  position: fixed;
  z-index: 4;
  border-bottom: 1px solid #e2e7ef;
  .line-chart-tooltip {
    background: white;
    padding: 9px 12px;
    border: 1px solid #ccc;
    display: none;
  }
}

.line-chart-container-cl {
    margin-top: 241px;
    .code-line-header {
      height: 40px;
      position: fixed;
      top: 305px;
      width: 250px;
      background:white;
      z-index: 1;
    }
}

@media (min-width: 1280px) {
  .line-chart-container-cl {
  .code-line-header {
    top: 311px;
  }
  }
}

.line-chart-container-sp {
  margin-top: 241px;
}
.line-chart-container-codel {
  margin-top: 201px;
  position: fixed;
  background: white;
  z-index: 1;
  overflow: scroll;
  width: calc(100% - 250px);
}

.line-chart-container-pr::-webkit-scrollbar {
  display: none;
}

.line-chart-container-codel::-webkit-scrollbar {
  display: none;
}

.line-chart-container-sp::-webkit-scrollbar {
  display: none;
}

.year-bar {
  height: 38px;
  display: flex;
  align-items: center;
  background: #e2e7ef;
  font-weight: 900;
  font-size: 16px;
  color: black;
}
.no-color {
  color: #e2e7ef;
}

.ponctual-operation:hover {
  cursor: pointer;
  filter: contrast(1.8);
}

.selected-operation {
  filter: contrast(1.8);
  cursor: pointer;
}

.operation-path:hover{
  cursor: pointer;
  filter: contrast(1.8);
}

.operation-path {
  filter: 
    drop-shadow(-1px -1px 0px #ffffff) 
    drop-shadow(2px -1px 0px #ffffff) 
    drop-shadow(2px 2px 0px #ffffff)
    drop-shadow(-1px 2px 0px #ffffff)
}

.operation-label {
  font-size: 13px;
  font-family: sans-serif;
  text-anchor: initial;
  pointer-events: none,
}

.operation-label-shadow {
  text-shadow: 0 0 0.5em #00ffd5, 0 0 0.5em #00ffd5,0 0 0.5em #00ffd5,0 0 0.5em #00ffd5, 0 0 0.5em #00ffd5,0 0 0.5em #00ffd5;
  font-size: 16px !important;
}

.legend-wrapper {
  bottom: 0;
  width: 100%;
  position: fixed;
  z-index: 1;
}

.legend-wrapper-exportpdf {
  bottom: 0;
  width: 2450px;
  position: relative;
} 

.open-settings-panel {
  margin-left: 250px;
}

.open-library-panel {
  margin-left: 280px;
}

.line-chart-pr-open-settings-panel {
  left: 500px;
  width: calc(100% - 500px);
}

.line-chart-pr-open-library-panel {
  left: 530px;
  width: calc(100% - 530px);
}

.code-line-chart-open-settings-panel {
  width: calc(100% - 500px);
}

.code-line-chart-open-library-panel {
  width: calc(100% - 530px);
}