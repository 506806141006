.panel-header {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 55px;
    background-color: #F5F6F6;
}

.panel-header-text {
    position: absolute;
    left: 60px;
    font-size: 12px;
    color: #545454;
}

.panel-header-icon {
    padding-left: 1rem;
    font-size: 20px;
}

.library-accordion {
    .accordion-button {
        height: 14px;
        font-weight: 900;
        font-size: 12px;
        color: #3C3C3B;
    }
    p {
        margin-bottom: 0 !important;
        margin-left: 1rem;
        color: #41A8F9;
    }
    .accordion-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem 1.8rem;
        gap: 16px;
        height: 55vh;
        overflow-y: auto;
    }
}

.library-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
    width: 425px;
    height: 190px;
    background: #FBFBFB;
    box-shadow: 1.97688px 1.97688px 0.98844px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    white-space: pre-wrap;
    font-size: 13px;
    line-height: 140%;
}

.library-label-outline {
    border: 0.3rem solid #ffff38;
}