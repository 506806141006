#access-denied {
    height: 100vh;
    font-family: Avenir;
  
    .warning-icon {
      margin: 1rem;
      height: 100px;
      width: auto;
    }
  
    .main-message {
      font-size: 2.25rem;
      font-weight: 900;
    }
  
}