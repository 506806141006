.folder-content-container {
    height: 100%;
    padding: 16px;
    margin-top: 2.5rem;
    .MuiGrid-item:first-child {
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 18px;
    }
    .MuiGrid-item {
        justify-content: space-between;
        align-items: center;
    }
    .search-bar {
        width: 50%;
    }
    .MuiButton-root {
        font-size: 12px;
        text-align: right;
        text-transform: none;
        justify-content: flex-start;
        .MuiSvgIcon-root {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }
    .label-style {
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        color: #050D12;
    }
}