.comments-form-container {
	display: flex;
	margin-right: 10px;
}

.comments-form-container .form-label {
	font-weight: 400;
	font-size: 10px;
	text-align: center;
	color: #6d6d6d;
	margin-left: 5px;
	margin-top: 10px;
}

.comment-row {
  margin: 0 15px 10px 15px !important;
	align-items: flex-end;
}

.comments-container {
  height: calc(90% - 326px);
  overflow: auto;
}