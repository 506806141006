.content-container {
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    align-items: center;
    background: #183B68;
    color: white;
}

.full-content-container {
    height: calc(100vh - 68px);
}

.geographic-content-container {
    height: 40vh;
    .content-container-input {
        margin-top: 60px;
    }
}

.content-container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-style {
    font-weight: 900;
    font-size: 36px;
    color: #FFFFFF;
    margin: 16px 0px;
}

.notice-style {
    color: #B6B6B6;
    margin: 16px 0px;
}

.content-container-input {
    margin-top: 129px;
    .input-group-text {
        background-color:  #F5F6F6;
        color: #272727;
    }
    .form-control {
        background-color:  #F5F6F6;
        color: #43454A;
    }
}

.settings-content-container {
    height: 30vh;
}

.container-header {
    height: 68px;
    background: #183B68;
    .MuiGrid-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 90%;
        margin: auto;
    }
    .MuiGrid-item {
        display: flex;
        align-items: center;
    }
    .MuiIconButton-root {
        color: #FFF;
        &:focus {
            outline: none !important;
        }
    }
    .MuiTypography-root {
        font-size: 12px;
        color: #FFF;
    }
}

.container-body {
    height: 60%;
    background-color: #183B68;
    .MuiGrid-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: auto;
    }
    .MuiGrid-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
    }
    .MuiTypography-h4 {
        font-weight: 800;
        color: #FFF;
        text-align: center
    }
    .MuiTypography-subtitle1 {
        font-weight: 400;
        color: #B6B6B6;
        text-align: center;
        padding-top: 16px;
    }
}

.container-footer {
    height: calc(40% - 68px);
    .MuiGrid-container {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        height: 100%;
    }
    .MuiGrid-item {
        margin-bottom: 2rem;
        width: 100%;
    }
}