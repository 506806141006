.operation-fields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 100%;
    .input-error-container {
        margin-top: 20px;
        margin-bottom: 5px;
        height: 100%;
    }
    .generic-input {
      width: 100%;
      .MuiPaper-root {
        background-color: #ffffff;
        border: 1px solid #bfbfc2;
        border-radius: 4px;
        height: 40px;
        width: 100%;
      }
    }
    .MuiOutlinedInput-root {
      height: 40px;
      padding: 2px !important;
    }
    .MuiIconButton-root {
      color: #3c3c3b;
    }
    .MuiIconButton-root:hover {
      background-color: transparent !important;
    }
  }

  .operation-creation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-height: 95%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.4375rem;
    outline: 0;
    padding: 1.5rem;
    gap: 20px;
  }
  
  .operation-modal-header {
    display: flex;
    justify-content: space-between;
    height: 32px;
    width: 100%;
    .MuiTypography-root {
      font-weight: 900;
      font-size: 12px;
      display: flex;
      align-self: center;
    }
    .MuiIconButton-root {
      color: #3c3c3b;
      .MuiSvgIcon-root {
        width: 0.8em;
        height: 0.8em;
      }
    }
  }
  
  .operation-modal-body {
    overflow: auto;
  }

.typology-select-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ferroloc-phase-container {
  width: 100%;
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.ferroloc-phase-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .MuiTypography-root {
    font-weight: 900;
    font-size: 12px;
  }
}

.ferroloc-fields {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  .generic-select {
    margin-right: 0;
    .MuiOutlinedInput-root {
      width: 150px;
      height: 40px;
    }
  }
  .generic-input {
    .MuiPaper-root {
      width: 150px;
    }
  }
  .MuiOutlinedInput-root {
    width: 225px;
  }
  .MuiIconButton-root {
    color: #3c3c3b;
  }
  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }
}

.phase-fields {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 100%;
  .MuiIconButton-root {
    color: #3c3c3b;
  }
  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }
}

.remove-btn-icon {
  display: flex;
  align-items: end;
  .MuiIconButton-root {
    height: 40px;
  }
}

.phase-select-input {
  width: 50%;
}

.operation-validation-btn {
  width: 470px;
  height: 50px;
  background-color: #41A8F9 !important;
  border-radius: 30px !important;
}
.move-operation-dialog {
  .MuiBackdrop-root {
    background-color: transparent !important;
  }
}


svg {
	overflow: visible !important;
}

.defected-operation-error-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #E83F3F;
  .defected-operation-error {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  > svg {
    width: 20px;
    height: 20px;
  } 
}