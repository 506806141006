.export-pdf-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 80%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  pointer-events: auto;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4375rem;
  outline: 0;
  .settings-container {
    display: flex;
    padding: 1.5rem 0 1.5rem 1.5rem;
    flex-direction: column;
    width: 40%;
  }
	.previsualization-container {
		width: 60%;
		background: #EFF3F6;
		border-radius: 0 0.4375rem 0.4375rem 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.export-pdf-header {
  display: flex;
  justify-content: space-between;
  height: 32px;
  width: 100%;
  .MuiTypography-root {
    font-weight: 900;
    font-size: 12px;
    display: flex;
    align-self: center;
  }
  .MuiIconButton-root {
    color: #3c3c3b;
    .MuiSvgIcon-root {
      width: 0.8em;
      height: 0.8em;
    }
  }
}

.export-pdf-cancel-btn {
  width: 90%;
  height: 50px;
  color: black !important;
  border-color: black !important;
  background-color: #ffffff !important;
  border-radius: 30px !important;
}

.previsualization-btn {
  z-index: 4;
  width: 30%;
  position: absolute;
  bottom: 25px;
  height: 50px;
  margin-top: 20px;
  background-color: #41a8f9 !important;
  border-radius: 30px !important;
}