.myp-settings-panel {
    position: fixed;
    z-index: 10;
    width: 250px;
    height: calc(100vh - 110px);
    top: 110px;
    left: 0;
    background: #FFFFFF;
    box-shadow: 0px 5px 14px 5px rgba(0, 0, 0, 0.07);
    transition: right 150ms ease-in-out;
    overflow: auto;
}

.myp-settings-panel-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 15px;
    gap: 16px;
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0px;
    top: 0px;
    background: #F5F6F6;
    .MuiTypography-root {
        font-weight: 600;
        font-size: 17px;
    }
    .MuiIconButton-root {
        color: #272727;
        padding: 0;
        > svg {
            width: 18px;
            height: 18px;
        }
    }
}

.myp-setting-panel-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 15px 15px 15px;
    gap: 15px;
    position: absolute;
    top: 60px;
    width: 100%;
    .category-title {
        font-weight: 900;
        color: #C6C6C6;
        font-size: 14px;
    }
    .category-title:first-of-type {
        margin-top: 1.5rem;
    }
    .filter-summary {
        text-align: start;
        text-transform: none;
        justify-content: flex-start;
        color: #3C3C3B;
        font-weight: 900;
        font-size: 14px;
        padding: 0;
        width: 100%;
    }
    .MuiButton-root {
        text-align: start;
        text-transform: none;
        justify-content: flex-start;
        color: #3C3C3B;
        font-weight: 900;
        font-size: 12px;
        padding: 0;
        width: 100%;
    }
    .MuiButton-root:hover {
        background-color: #FFFFFF;
    }
}

.filters-container {
    width: 100%;
}

.filters-accordion {
    box-shadow: none !important;
    margin: 0 !important;
    .MuiAccordionSummary-root {
        padding: 0 !important;
        min-height: 30px !important;
        .MuiTypography-root {
            font-size: 12px;
            color: #3C3C3B;
            margin: 0;
        }
        .MuiAccordionSummary-expandIconWrapper {
            color: #3C3C3B;
        }
        .MuiIconButton-root {
            color: #41A8F9;
        }
    }
    .MuiAccordionSummary-content {
        align-items: center;
        margin: 0;
    }
}

.validate-filter-btn {
    padding-top: 25px;
    .MuiButton-root {
        display: flex;
        justify-content: center;
        border-radius: 15px !important;
        background-color: #41A8F9;
        color: #FFF;
        font-weight: 500;
    }
    .MuiButton-root:hover {
        background-color: #41A8F9;
    }
}

.filters-accordion::before {
    content: none !important;
}

.add-myp-filter {
    width: 100%;
    height: 39px;
    border: 1px solid rgba(24, 59, 104, 0.1);
    border-radius: 4px;
    font-size: 0.75rem;
    .MuiOutlinedInput-root {
        padding: 3px !important;
        font-size: 0.75rem !important;
    }
}