.action-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    background-clip: padding-box;
    border: 0 solid rgba(0,0,0,.2);
    border-radius: 0.4375rem;
    outline: 0;
    padding: 1.5rem;
    .MuiTypography-h6 {
        font-size: 1.5rem !important;
    }
    .MuiTypography-body1 {
        font-weight: 300 !important;
        color: #a7a9aa !important;
        padding: 0 1.5rem;
    }
    .btn {
        background-color: transparent !important;
        background-image: none;
        border-radius: 1.4375rem !important;
        height: 35px;
        padding: 0.125rem 1.25rem !important;
    }
}

.modal-action-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.5rem;
}