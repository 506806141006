.sort-by-text {
    color: #868686;
}

.sort-icons {
    color: #43454A;
    padding-left: 3px;
}

.sort-options {
    font-size: 12px !important;
}