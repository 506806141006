.inputs-container {
    flex-direction: row !important;
    flex-wrap: wrap;
}

.add-btn-container {
    display: flex;
    align-items: flex-end;
    height: 102px;
    margin-bottom: 2rem;
    .add-section-btn {
        display: flex ;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px !important;
        gap: 7px;
        width: 180px;
        height: 31px;
        background: rgba(255, 255, 255, 0.1) !important;
        border: 1px solid rgba(255, 255, 255, 0.4) !important;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.06);
        border-radius: 4px !important;
        &:focus {
            outline: none !important;
        }
    }
}

.input-style {
    margin-right: 24px;
    height: 64px;
    .MuiPaper-root {
        width: 240px !important;
    }
}

.step-input-style {
    .MuiPaper-root {
        width: 70px !important;
    }
}

.declaration-choice {
    display: flex;
    gap: 24px;
    .declaration-choice-btn {
        background: rgba(255, 255, 255, 0.1) !important;
        border: 1px solid rgba(255, 255, 255, 0.4) !important;
        box-shadow: 1px 2px 3px rgb(0 0 0 / 6%);
        border-radius: 4px !important;
    }
}

.section-container {
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    background-color: #f2f2f2;
}

.table-wrapper {
    height: 30vh;
    width: 65vw !important;
    overflow: auto;
    .table {
        th {
            padding-bottom: 1rem !important;
        }
        tbody {
            tr {
                box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
                background-color: #fff !important;
                border-radius: 4px;
            }
            td {
                font-weight: 800;
                font-size: 12px;
                vertical-align: middle;
            }
            .td-order {
                width: 56px;
                text-align: center;
            }
            .section-label {
                padding-left: 1rem !important;
                width: 20vw;
            }
            td:last-child {
                padding-right: 1.25rem !important;
                display: flex;
                justify-content: center;
                svg {
                    color: #439CD1
                }
            }
        }
    }
}

.draggable-style {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.geographic-content-container {
    height: 100vh;
    overflow: hidden;
    .container-body {
        height: 45vh !important;
        .MuiGrid-item {
            margin-top: 0 !important;
            height: 88px;
            align-items: unset !important;
        }
        .inputs-container {
            margin-bottom: 30px !important;
        }
    }
    .add-btn-container {
        align-items: center;
        margin-bottom: 0 !important;
        .add-section-btn {
            margin-bottom: 10px;
        }
    }
}

.file-search-bar {
    width: 700px;
    height: 40px;
    background: #F5F6F6;
    border-radius: 4px;
    .MuiOutlinedInput-root {
        padding: 3px 9px !important;
    }
    .MuiAutocomplete-input {
        font-size: 14px;
        color: #464646;
    }
    .MuiAutocomplete-input::placeholder {
        color: #C0C0C0;
    }
    .MuiOutlinedInput-notchedOutline {
        border: 1px solid #41A8F9;
    }
}

.options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    color: #3C3C3B;
    padding: 12px 20px;
    gap: 10px;
    .MuiChip-root {
        height: 25px;
        border-radius: 4px;
    }
}

.options-container:hover {
    background-color: rgba(0,0,0,0.075) !important;
}

.reset-section-notice {
    box-shadow: 3px 4px 4px rgb(0 0 0 / 8%), 0px 1px 5px rgb(0 0 0 / 6%) !important;
    border-radius: 8px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #cd0037 !important;
    padding: 1.25rem;
    margin: 16px;
    svg {
        height: 2em;
        width: 2em;
    }
    .MuiTypography-root {
        padding-left: 20px;
        font-weight: 500 !important;
    }
}