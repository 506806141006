.library-panel {
    width: 280px;
}
.library-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    gap: 16px;
    width: 100%;
    .MuiTypography-root {
        font-weight: 900;
        font-size: 16px;
    }
    .MuiIconButton-root {
        color: #272727;
        padding: 0;
        > svg {
            width: 18px;
            height: 18px;
        }
    }
}

.library-panel-search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 16px;
    gap: 16px;
    border: 1px solid #DAE3EC;
    box-shadow: none !important;
    > svg {
        color: #5F6670;
    }
    .MuiInputBase-root {
        font-size: 14px !important;
    }
}

.library-panel-accordion {
    width: 100%;
    box-shadow: none !important;
    margin: 0 !important;
    .MuiAccordionSummary-root {
        padding: 0 !important;
        min-height: 25px !important;
        .MuiTypography-root {
            display: flex;
            align-items: center;
            gap: 6px;
            font-weight: 900;
            font-size: 14px;
            color: #3C3C3B;
            margin: 0;
        }
        .MuiAccordionSummary-expandIconWrapper {
            color: #3C3C3B;
        }
    }
    .MuiAccordionSummary-content {
        align-items: center;
        margin: 0;
        .operations-count {
            color: #41A8F9;
            font-size: 12px;
        }
    }
    .MuiAccordionDetails-root {
        padding: 0 !important;
    }
}

.library-setting-btn {
    gap: 6px;
    .MuiTypography-root {
        color: #41A8F9;
        font-size: 12px;
        font-weight: 900;
    }
}

.library-panel-accordion::before {
    content: none !important;
}

.operation-card-container {
    padding: 0;
    .MuiGrid-item {
        width: 100%;
    }
}

.operation-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #DAE3EC;
    box-shadow: none !important;
    padding: 6px;
    min-height: 138px;
    .MuiCardHeader-root {
        padding: 8px !important;
        .MuiTypography-root {
            font-weight: 500;      
            font-size: 12px;
            text-align: justify;

        }
    }
    .MuiCardActions-root {
        .MuiButton-root {
            border-radius: 64px;
            padding: 6px 14px;
            font-weight: 500;
            font-size: 10px;
            text-align: center;
            justify-content: center;
        }
        .fix-anomaly-btn{
            background: #E83F3F;
            color: #FFF;
            box-shadow: none;
        }
        .delete-operation-btn {
            color: #E83F3F;
            border: 1px solid #E83F3F;

        }
    }
}

.library-operation-highlight {
    border: 0.3rem solid #00ffd5;
}