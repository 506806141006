.folder-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent !important;
    width: 186px;
    box-shadow: none !important;
    > svg {
        width: 150px;
        height: 100px;
        fill: #80BADB !important;
        > path:first-child {
            fill: #7EC2EA !important;
        }
        > path:last-child {
            fill: #94CCEC !important;
        }
    }
    .MuiInputBase-input {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        text-overflow: ellipsis;
    }
}

.date-style {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #41A8F9;
    padding-top: 2px;
}

.folder-container {
    align-content: flex-start;
    justify-content: start !important;
}

.context-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    border: 1px solid #D9D9D9;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06) !important;
    border-radius: 4px !important;
    cursor: pointer;
    .context-menu-text {
        padding: 12px;
        font-size: 0.875rem;
        min-height: 43px;
        display: flex;
        align-items: center;
    }
    .context-menu-text:hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
    .notice {
        color: #A5A5A5;
        font-size: 12px;
    }
}

.editable-input {
    background: #95CCF8;
}