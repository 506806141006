.home-page-banner {
    background-size: cover;
    background-position: center;
    height: 40%;
    margin-top: 44px;
    background-repeat: no-repeat;
    border-radius: 0px 0px 100px 0px;
}

.banner-grid {
    height: 230px;
    width: 100%;
    padding-left: 3;
}

.banner-title {
    font-family: 'Avenir' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 56px !important;
    color: #000000;
}

.banner-subtitle {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #6A6A6A;
}

.repertory-container {
    height: 100%;
    padding: 16px;
    justify-content: center;
    margin: auto;
    .MuiGrid-item {
        justify-content: space-between;
        padding: 11px;
        align-items: center;
    }
    .MuiTypography-root {
        font-size: 12px;
        color: #868686;
    }
    .MuiButton-root {
        font-size: 12px;
        text-align: right;
        text-transform: none;
        min-width: 180px;
        justify-content: flex-start;
        .MuiSvgIcon-root {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }
    .MuiButton-root:focus {
        outline: none;
    }
}

.repertory-card-container {
    align-content: flex-start;
    justify-content: start !important;
    overflow: auto;
    height: 38vh;
}

.add-repertory-btn {
    background-color: #41A8F9 !important;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06);
    border-radius: 80px !important;
    position: absolute;
    bottom: 2%;
    &:focus {
        outline: none !important;
    }
}

.repertory-card {
    width: 90%;
    height: 223px;
    background-color: rgba(0, 0, 0, 0.1) !important;
    background-size: cover;
    margin: 10px;
    box-shadow: 0px -200px 75px -129px rgba(0,0,0,0.75) inset !important;
    .MuiIconButton-root {
        width: 32px;
        height: 32px;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        &:focus {
            outline: none !important;
        }
    }
    .MuiCardHeader-avatar {
        margin-right: 0 !important;        
    }
    .MuiCardHeader-action {
        margin: 0 !important;
    }
    .MuiSvgIcon-root {
        color: #FFF;
    }
    .MuiCardActionArea-root {
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        &:focus {
            outline: none !important;
        }
    }
    .MuiTypography-root {
        color: #FFF !important;
        font-family: 'Avenir';
        font-style: normal;
        font-weight: 900;
        font-size: 20px;
        text-align: center;
    }
}