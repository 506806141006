.search-bar {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 75%;
    .MuiInputBase-root {
        font-family: 'Avenir';
        font-style: normal;
        font-size: 12px;
    }
}