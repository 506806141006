.action-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    background-clip: padding-box;
    border: 0 solid rgba(0,0,0,.2);
    border-radius: 0.4375rem;
    outline: 0;
    padding: 1.5rem;
    .MuiTypography-h6 {
        font-size: 1.5rem !important;
    }
    .MuiTypography-body1 {
        font-weight: 300 !important;
        color: #a7a9aa !important;
        padding: 0 1.5rem;
    }
    .btn {
        background-color: transparent !important;
        background-image: none;
        border-radius: 1.4375rem !important;
        height: 35px;
        padding: 0.125rem 1.25rem !important;
    }
}

.delete-btn {
    color: #cd0037 !important;
    border-color: #cd0037 !important;
    &:focus {
        outline: none !important;
    }
}

.update-btn {
    color: #0088ce !important;
    &:focus {
        outline: none !important;
    }
    &:disabled {
        color: #b9b9b9 !important;
    }
}

.cancel-action-btn {
    color: #4d4f53 !important;
    border-color: #4d4f53 !important;
    &:focus {
        outline: none !important;
    }
}

.move-operation-cancel-action-btn {
    color: #000000 !important;
    background-color: #EBF0F9 !important;
    border-color: #EBF0F9 !important;
    &:focus {
        outline: none !important;
    }
    &:hover {
        background-color: #f5f6f9 !important;
    }
}


.move-operation-save-action-btn {
    color: #ffffff !important;
    border-color: #ffffff !important;
    &:focus {
        outline: none !important;
    }
}