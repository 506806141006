.navigation-tab {
    background-color: #43454a;
    height: 44px;
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 3.75rem;
    z-index: 6;
    .nav-link {
        padding-left: 1rem;
        color: white;
        font-size: 0.8rem;
        height: 44px;
        min-width: 44px;
        display: flex;
        align-items: center;
    }
    :first-child > .nav-link {
        padding-right: 1rem;
    }
    .active {
      background-color: #606164;
      color: white !important;
    }
    .nav-item:hover {
        background-color: #606164;
    }
}

@media (min-width: 1280px) {
.navigation-tab {
    top: 4.0625rem;
}
}

.synoptic-navigation-tab {
    background-color: white;
    height: 54px;
    display: flex;
    align-items: center;
    margin-top: 44px;
    .nav-link {
        padding: 0 1rem;
        color: #dadada;
        font-size: 0.8rem;
        height: 54px;
        display: flex;
        align-items: center;
    }
    .active {
      color: #272727 !important;
      font-size: 12px;
    }
    .nav-link:hover {
        color: #272727;
    }
}

.action-cursor-pointer:hover {
    cursor: pointer;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.container-view {
    width: 100%;
    background: #F5F6F6;
    border-radius: 0px;
}

.dropdown-menu::after, .dropdown-menu::before {
    border: none !important;
}

.form-select {
    border-radius: 4px;
    height: 45px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23333;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-down%3C/title%3E%3Cpath class='a' d='M25,40.42,1.15,17a4.69,4.69,0,0,1,0-6.15A5.15,5.15,0,0,1,4.23,9.65a4.38,4.38,0,0,1,3.08,1.16L25,28.12,42.69,10.81a4.6,4.6,0,0,1,6.93.77,5.39,5.39,0,0,1-1.16,5.77Z'/%3E%3C/svg%3E"),linear-gradient(to left, #fff 3.125rem, #fff 3.125rem);
}

.form-select:focus {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23333;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Earrow-up%3C/title%3E%3Cpath class='a' d='M25,9.58,48.85,33a4.69,4.69,0,0,1,0,6.15,5.15,5.15,0,0,1-3.08,1.16,4.38,4.38,0,0,1-3.08-1.16L25,21.88,7.31,39.19a4.6,4.6,0,0,1-6.93-.77,5.39,5.39,0,0,1,1.16-5.77Z'/%3E%3C/svg%3E"),linear-gradient(to left, #fff 3.125rem, #fff 3.125rem);
    border: none;
}

.panel {
    width: 500px;
    background-color: white;
    height: calc(100vh - 163px);
    position: relative;
    transition: width 150ms ease-in-out;
}

.panel-close {
    width: 0;
    background-color: white;
    height: calc(100vh - 163px);
    position: relative;
    transition: width 150ms ease-in-out;
}

.mapboxgl-ctrl-compass {
    cursor: pointer !important;
}

.actions-menu-btn {
    color: #3C3C3B !important;
    text-transform: none !important;
    width: 100%;
    display: flex !important;
    justify-content: flex-start !important;
  }
  
.actions-menu-btn:hover {
    background-color: #f5f5f5 !important;
}
  
.actions-menu-btn:focus {
    outline: none;
}

.MuiOutlinedInput-notchedOutline {
    > legend {
        width: 0 !important;
    }
}
