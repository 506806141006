.export-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;

  .input-field,
  .input-error {
    width: 240px !important;
  }
}

.svg-container {
  overflow: auto;
  width: 100%;
  display: contents;
  .react-transform-wrapper {
    width: 100%;
    height: 100%;
  }
  .react-transform-component {
    display: block;
    min-width: 100%;
  }
  img {
    height: 100%;
  }
}

.export-tool-bar {
  position: absolute;
  left: 0;
  top: 5.5rem;
  z-index: 1;
  background-color: #404040 !important;
  transition: right 150ms ease-in-out;
}

.tool-bar-btn {
  color: #fff !important;
  &:focus {
    outline: none !important;
  }
}

.object-legend-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px;
  gap: 5px;
  min-width: 330px !important;
  height: 90%;
  background: #ffffff;
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    width: 100%;
  }
}

.legend-container {
  min-width: 320px !important;
  overflow: auto;
  padding-right: 20px;
  padding-left: 6px;
  height: 100%;
}

.no-portfolio-legend {
  right: 0;
}

.object-legend {
  font-weight: 400;
  font-size: 13px;
  color: #43454a;
}

.legend-btn {
  display: flex;
  flex-direction: row-reverse;
  color: #41a8f9;
  padding-right: 0 !important;
}

.legend-header {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  color: #43454a;
}

.portfolio-legend-container {
  padding: 23px;
  width: 330px;
  height: 90%;
  background: #ffffff;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 100%;
    margin-top: 1rem;
  }
  .card {
    padding: 16px;
    width: 20px;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }
}

.porfolio-legend {
  font-size: 15px !important;
  font-weight: 500;
  color: #000000;
  display: flex;
  justify-content: center;
}

.cartouche-container {
  min-width: 474px;
  height: 90%;
  background: #ffffff;
}

.cartouche-header {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 6px;
  gap: 20px;
  img {
    width: 44px;
    height: 30px;
    margin-right: 1rem;
  }
  .dropend {
    display: flex;
    align-self: baseline;
    margin-right: 1rem;
  }
}

.cartouche-header-text {
  font-weight: 400;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  text-transform: uppercase;
  color: #272727;
}

.form-container {
  textarea {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #bfbfc2;
    border-radius: 4px;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    font-weight: 400;
    resize: none !important;
  }
  .form-control {
    color: #3c3c3b !important;
  }
  .form-control:focus {
    background-color: #ffffff !important;
  }
}

.cartouche-title-container {
  textarea {
    min-height: 5rem !important;
    font-size: 12px;
  }
}

.cartouche-warnings-container {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #bfbfc2;
  border-radius: 4px;
  margin: 15px 16px;
  min-height: 13rem;
  textarea {
    border: none;
    min-height: 11rem !important;
    font-size: 9px;
  }
  .input-field {
    background: #fff !important;
  }
}

.cartouche-warnings-container:focus-within {
  border-color: #4fc3ff;
}

.warning {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #3c3c3b;
  svg {
    margin-right: 0.5rem;
    color: #fc5130;
    height: 1.25em;
  }
}

.version-row {
  margin: 0 15px 10px 15px !important;
}

.versions-form-container {
  display: flex;
  .form-control {
    box-sizing: border-box;
    background: #ffffff;
    border: 1px solid #bfbfc2;
    border-radius: 4px;
  }
  .form-control:focus {
    background: #ffffff !important;
  }
  .form-label {
    font-weight: 400;
    font-size: 10px;
    text-align: center;
    color: #6d6d6d;
  }
}

.versions-form-container > div:first-child {
  width: 50px;
}

.versions-form-input {
  font-size: 10px !important;
  .form-control {
    padding: 5px;
  }
  textarea {
    min-height: 2.8125rem !important;
    resize: none !important;
    padding: 13px 5px 0 5px !important;
  }
}

.transform-component-module_wrapper__1_Fgj {
  width: 100%;
}

.versions-container {
  height: calc(90% - 326px);
  overflow: auto;
}

.legend-modal {
  .modal-dialog {
      max-width: 352px;
  }
  .modal-footer {
      .btn {
          border-radius: 1.4375rem !important;
      }
  }
}

.export-settings-modal {
  align-items: flex-start !important;
  padding: 0 !important;
  .MuiTypography-h6 {
    padding: 15px 24px;
    border-bottom: 1px solid #D2DFEC;
    width: 100%;
  }
  .generic-input {
    padding: 24px;
    .MuiInputLabel-root {
      color: #8A8A8A;
    }
    .MuiPaper-root {
      border: 1px solid #BFBFC2;
    }
    .input-error-container {
      margin-top: 15px;
    }
  }
  .cartridge-checkbox {
    padding: 0 24px;
    .MuiTypography-body1 {
      color: #3C3C3B !important;
      padding: 0;
      font-weight: 400 !important;
    }
  }
  .modal-action-btn {
    flex-direction: column;
    padding: 24px;
    gap: 15px;
    .btn {
      width: 100%;
    }
  }
}

.export-scaling-label {
  color: #8A8A8A !important;
  padding: 0 28px !important;
}

.export-scaling-fields {
  display: flex;
  .generic-input {
    padding-top: 0;
    .MuiPaper-root {
      width: 175px;
    }
  }
}
