.action-button {
    padding: 6px 6px 6px 8px !important;
    position: absolute;
    bottom: 5%;
    background-color: #41A8F9;
    border-radius: 30px !important;
    width: 400px;
    height: 56px;
    > svg {
        margin: 0px 16px;
    }
    &:focus {
        outline: none !important;
    }
    &:disabled {
        color: #fff !important;
        background-color: #b9b9b9 !important;
    }
}

.skip-btn {
    padding: 6px 6px 6px 8px !important;
    width: 360px;
    height: 56px;
    border: 2px solid rgba(255, 255, 255, 0.1) !important;
    border-radius: 30px !important;
    background-color: transparent !important;
    &:focus {
        outline: none !important;
    }
}

.cancel-btn {
    padding: 6px 6px 6px 8px !important;
    width: 360px;
    height: 56px;
    background: rgba(255, 255, 255, 0.3) !important;
    border: 2px solid rgba(255, 255, 255, 0.1) !important;
    box-shadow: 2px 2px 6px rgba(255, 255, 255, 0.08), -2px -2px 7px rgba(255, 255, 255, 0.06);
    border-radius: 30px !important;
    color: #183B68 !important;
    &:focus {
        outline: none !important;
    }
}

.pic-validation-btn {
    padding: 6px 6px 6px 8px !important;
    width: 360px;
    height: 56px;
    background: #F5F6F6 !important;
    border-radius: 30px !important;
    color: #183B68 !important;
    border-color: #F5F6F6 !important;
    box-shadow: 2px 2px 6px rgba(255, 255, 255, 0.08), -2px -2px 7px rgba(255, 255, 255, 0.06);
    > svg {
        margin: 0px 16px;
    }
    &:focus {
        outline: none !important;
    }
}

.drop-zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 48px;
    width: 422px;
    height: 281px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
}

.btn-icon {
    margin: 0px 16px;
}

.repertory-banner {
    margin-top: 44px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #022e4f;
    border-radius: 0px 0px 12px 0px;
    .MuiGrid-root {
        flex-basis: 100% !important;
        max-width: 100% !important;
    }
    .MuiGrid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .MuiGrid-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 24px;
        margin-bottom: 24px;
    }
    .MuiButton-root {
        padding: 10px 12px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 4px;
        text-transform: none !important;
        color: #616465;
        box-shadow: none !important;
        &:hover {
            background: #FFFFFF !important; 
        }
    }
    .MuiTypography-root {
        font-weight: 600;
        font-size: 32px;
        line-height: 120%;
        color: #000000;
        padding: 24px 0 0 0;
    }
}




.repertory-content-container {
    height: 100%;
    padding: 16px;
    .MuiGrid-item {
        justify-content: space-between;
        align-items: center;
    }
    .search-bar {
        width: 50%;
    }
    .MuiButton-root {
        font-size: 12px;
        text-align: right;
        text-transform: none;
        min-width: 180px;
        justify-content: flex-start;
        .MuiSvgIcon-root {
            width: 12px;
            height: 12px;
            margin-left: 5px;
        }
    }
}