.file-card {
    width: 292px;
    height: 190px;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%) !important;
    border-radius: 0% !important;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .MuiCardHeader-root {
        padding: 0 !important;
        width: 244px;
        .MuiTypography-root {
            font-weight: 500;
            font-size: 16px;
        }
    }
    .MuiCardHeader-content {
        width: 220px;
        overflow-wrap: break-word;
    }
    .MuiCardHeader-action {
        width: 24px;
        height: 24px;
        background: #F0F0F0;
        border-radius: 4px;
        margin: 0 !important;
        .MuiIconButton-root {
            padding: 0 !important;
            > svg {
                color: #C6C6C6;
            }
        }
    }
    .MuiCardContent-root {
        padding: 8px 0 0 0 !important;
        .MuiTypography-root {
            font-size: 10px;
            color: #41A8F9;
        }
    }
    .MuiCardActions-root {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0px;
        .MuiButton-root {
            justify-content: center;
            padding: 6px 6px 6px 8px;
            width: 92px;
            height: 30px;   
            border: 1px solid #404040;
            border-radius: 30px;
            color: #43454A;
            font-weight: 400;
            text-transform: none;
            font-size: 12px;
        }
        .MuiChip-root {
            border-radius: 4px;         
            margin-bottom: 4px;

        }
    }
}

.file-creation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 24px;
    .MuiTypography-root {
        font-weight: 500;
        font-size: 24px;
        color: #41A8F9;
    }
    .creation-modal-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .file-btn-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-top: 32px;
    }
    .MuiButton-root {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        width: 483px;
        height: 72px;
        background: #FFFFFF;
        border: 1px solid #DCDCD9;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        text-transform: none;
        &:hover {
            box-shadow: none;
            background: #FFFFFF;
        }
    }
}

.rename-input {
    .MuiInputLabel-root {
        color: rgba(0, 0, 0, 0.87);
    }
    .MuiPaper-root {
        border: 1px solid #E1E1E1;
    }
    .MuiInputBase-root {
        width: 100%;
        padding: 4px 8px !important;
        margin-left: 0 !important;
    }
}