.parametrage-container {
    height: calc(85% - 145px);
    overflow: hidden;
}

.values-panel {
    height: 65vh;
    border: 1px solid #DADADA;
    margin: 30px;
    overflow: hidden auto;
    background: #FFFFFF;
}

.add-object-family-btn-container {
    margin: 30px;
}

.object-family-container {
    margin: 30px;
    height: calc(100% - 120px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.family-param-container {
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.object-family-label-input-container {
    display: flex;
    flex-direction: column;
    width: 25%;
}

.object-family-label-input {
    background: #FFFFFF;
    border: 1px solid #BCC6DB;
    border-radius: 4px;
    padding-left: 0.625rem;
    font-weight: 900 !important;
}

.object-family-label-input-error {
    .MuiInputBase-root {
        border: 1px solid #FF5737;
    }
    .input-error-container {
        margin-top: 8px;
    }
}

.add-track-container {
    display: flex;
    align-items: center;
    margin-left: 16px;
    gap: 15px;
    flex-wrap: wrap;
    .MuiChip-root {
        border-radius: 4px !important;
        height: 27px;
    }
    .MuiChip-root span {
        line-height: 1;
    }
    .MuiInputBase-input{
        background-color: #FFFFFF;
        border: 1px solid #BCC6DB;
        border-radius: 4px;
    }
}

.operations-container {
    height: 109px;
    border: 1px solid #DADADA;
    box-shadow: none !important;
    border-radius: 0 !important;
    overflow: hidden auto;
    .MuiChip-root {
        .MuiChip-deleteIcon {
            color: black;
            background: white;
            border-radius: 50%;
            padding: 3px;
        }
    }
    background-color: #FFFFFF;
}

.family-color-container {
    height: 109px;
    width: 110px;
    background: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.operation-grid-container {
    align-items: center;
    gap: 16px;
}

.object-family-color-panel {
    .MuiMenu-list {
        padding: 0;
    }
    .twitter-picker {
        > div:nth-of-type(1) {
            opacity: 0;
        }
        > div:nth-of-type(2) {
            transform: translateY(190%) translateX(-135%) rotate(-90deg)
        }
        width: 204px !important;
        input {
            display: none;
        }
        & :last-child {
            & :nth-last-child(3) {
                display: none !important;
            }
        }
    }
}

.track-chip:hover {
    cursor: pointer;
}

.chip {
    max-width: 100%;
    font-family: Avenir;
    font-size: 0.8125rem;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0px;
    text-decoration: none;
    border: 0px;
    padding: 0px;
    vertical-align: middle;
    box-sizing: border-box;
    margin: 0.25rem;
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 12px;
        padding-right: 12px;
        white-space: nowrap;
    }
}