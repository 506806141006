.myp-import-confirmation-btn {
    padding: 6px 6px 6px 8px !important;
    width: 398px;
    height: 56px;
    background-color: #FFFFFF !important;
    border-radius: 30px !important;
    color: #183B68 !important;
}

.myp-import-generation-btn {
    padding: 6px 6px 6px 8px !important;
    width: 398px;
    height: 56px;
    background-color: #41A8F9 !important;
    border-radius: 30px !important;
}

.myp-import-generation-btn:hover {
    padding: 6px 6px 6px 8px !important;
    width: 398px;
    height: 56px;
    background-color: #1a9bfd !important;
    border-radius: 30px !important;
}

.myp-import-regeneration-btn {
    padding: 6px 6px 6px 8px !important;
    width: 398px;
    height: 56px;
    color: #41A8F9 !important;
    background-color: #ffffff !important;
    border-radius: 30px !important;
}

.myp-import-regeneration-btn:hover {
    padding: 6px 6px 6px 8px !important;
    width: 398px;
    height: 56px;
    color: #ffffff !important;
    background-color: #41A8F9 !important;
    border-radius: 30px !important;
}


.add-object-family-btn {
    padding: 6px 6px 6px 8px !important;
    background-color: #FFFFFF !important;
    border-radius: 4px !important;
    width: 100%;
    border: none !important;
    color: #183B68 !important;
}

.add-track-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px !important;
    width: 171px;
    height: 28px;
    background: #FFFFFF !important;
    border-radius: 4px !important;
    color: #242830 !important; 
    border: none !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    text-decoration-line: underline;
}

.delete-object-family-btn {
    border-radius: 118px !important;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    .MuiSvgIcon-root {
        color: #050D12
    }
}